<template>


    <modal :title="this.form.title" @close="$emit('close')" :key="this.componentKey">

        <template v-slot:head-button>

        </template>

        <template v-slot:body>

            <!-- 
                Компонент таблиці 
                @search - дія пошуку
                @addCategory - дія додавання табу
                @removeCategory - дія видалення табу
                @create - дія створення тікету
                @open - дія відкриття тікету
                @changeFilter - дія зміни фільтру (табу)
                @clearFilter - дія очистки фільтру
                @remove - дія видалення тікету
                @getdata - дія отримання даних по тікетам
                @changelimit - зміна кількості відображення тікетів
                @changeTab - зміна табу
                :dataParams - пропс базових параметрів
                :objParams - пропс передачі парметрів (фільтрів, що будуть доступні)
                :columns - прпос передачі колонок
                :rows - пропс передачі самих тікетів
                :pages - пропс передачі номеру сторінки
                :showpag - пропс показування пагінації
                :tabs - пропс, що передає інформацію про таби
                :addtabs - дозвіл на додавання своїх табів
                :createdform - пропс на додавання тікетів
            -->
            <tablecustom 
                @search="searchB"
                @create="createmodal = true"
                @open="open" 
                @getdata="getdata"
                :columns="columns" 
                :rows="this.form.data.items" 
                @sortColumn="sortColumn"
                :activeSortColumn="activeSortColumn" 
                :objParams="objParams"
            />

        </template>

        <template v-slot:footer-bottom>


        </template>

    </modal>

    <!-- Модальне вікно тікету -->
    <viewTicketBox 
        v-if="showModalTicket" 
        @close="showModalTicket = false" 
        :obj="objCardTicket" 
    />

</template>

<script>
import modal from '@/components/modal/modal-lg'
import tablecustom from '@/components/globaltable/index';
import viewTicketBox from '../view/index'
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { mutateTypeTicket, 
        mutateWayproblem,
        mutateStatusTicket,
        mutateDecidedStatusTicket,
        mutatePriorityTicket,
        mutateProblemEnv,
        nameTag
      } from '@/usabilityScripts/globalMutate.js'
import { Tickets } from '@/API.js';

let apiServe = new Tickets();

export default {
    props: ['objCard'],
    components: {
        modal,
        tablecustom,
        viewTicketBox
    },
    data() {
        return {
            form: '', // дані форми
            objCardTicket: {},
            showModalTicket: false,
            objParams: {
                pagelimit: '1000',
                // search: '',
                priority: '',
                problemEnvironments: [],
                of: 'priority',
                ot: 'desc'
            },
            activeSortColumn: null, // для збереження активної колонки сортування
            columns: [
                {
                    name: "ID",
                    text: "ticketId",
                    align: "left",
                    status: true,
                    width: '10',
                    sort: 'id'
                },
                {
                    name: this.$t("ticket"),
                    text: "type",
                    align: "left",
                    mutate: (item) => this.mutateticket(item),
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("problem_area"),
                    text: "problemEnvironment",
                    align: "left",
                    mutate: (item) => item != null ? this.mutateProblemEnv(item) : '-',
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("ticket_name"),
                    text: "ticketName",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateTicketName(item),
                    width: '10'
                },
                {
                    name: this.$t("priority"),
                    text: "priority",
                    align: "right",
                    mutate: (item) => this.mutatepriority(item).name,
                    mutateClass: (item) => this.mutatepriority(item).color,
                    status: true,
                    sort: 'priority',
                    sortType: 'desc',
                    width: '10'
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "right",
                    mutate: (item) => this.mutatestatus(item).name,
                    mutateClass: (item) => this.mutatestatus(item).color,
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("decidedStatus"),
                    text: "decidedStatus",
                    align: "right",
                    mutate: (item) => this.mutateDecidedStatus(item).name,
                    mutateClass: (item) => this.mutateDecidedStatus(item).color,
                    status: true,
                    width: '10'
                },
            ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.objCard

            if (this.objCard.type == "ticketsClient") {
                this.objCard.phone = this.objCard.filter
            } else if (this.objCard.type == "ticketsByEnv") {
                var problemEnvironmentsArray = []
                problemEnvironmentsArray.push(this.objCard.filter)

                this.objParams.problemEnvironments = JSON.stringify(problemEnvironmentsArray)
            } else {
                this.objParams.inJobWorkerId = this.objCard.filter
            }

        },
        open(e){
            // Відкрити тікет

            apiServe.getTicket(e.ticketId).then(result => {
                if(result.status == 'done'){
                    this.objCardTicket = result.data;
                    this.showModalTicket = true;
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        },
        sortColumn(e, o){
            // сортування

            // Якщо клік по іншій колонці, скидаємо сортування для інших
            if (this.activeSortColumn == null || this.activeSortColumn !== e.sort) {
                this.activeSortColumn = e.sort;
                this.objParams.of = this.activeSortColumn
                this.objParams.ot = 'asc'; // початкове сортування за зростанням
            } else {
                // Перемикаємо між 'asc' та 'desc'
                this.objParams.ot = this.objParams.ot === 'asc' ? 'desc' : 'asc';
            }

            this.getTickets()
        },
        getTickets(){
            // отримання тікетів

            apiServe.getTickets(this.objParams).then(result => {
                if(result.status === 'done') {
                    this.form.data.items = result.data.items
                } else {

                }
            })

        },
        mutateTicketName(e) {
            // скорочує назву тікету

            let name = ''
            if(e.length > 50) {
                name = e.slice(0, 46) + '...'
            } else {
                name = e
            }
            return name
        },
        nameWorker(item){
            // мутейт співробітника
            return nameWorker(item)
        },
        mutateticket(e){
            // мутейт типу тікета
            return mutateTypeTicket(e)
        },
        mutatewayproblem(e){
            // мутейт типу проблеми
            return mutateWayproblem(e)
        },
        mutatestatus(e){
            // мутейт статусу
            return mutateStatusTicket(e)
        },
        mutateDecidedStatus(e){
            // мутейт типу статусу, коли тікет "Виконано"
            return mutateDecidedStatusTicket(e)
        },
        mutatepriority(e){
            // мутейт пріорітету
            return mutatePriorityTicket(e)
        },
        mutateProblemEnv(e){
            // мутейт середовища проблеми
            return mutateProblemEnv(e)
        },

    }
}
</script>